import React from "react";
import Card from 'react-bootstrap/Card';
import { Link } from "gatsby"
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem';
import { useStaticQuery, graphql } from 'gatsby';
import FontIcon from '../components/fonticon'

const Coursecard = () => {
	const data = useStaticQuery(graphql`
    query {
			allCourseDataset(filter: {Type: {eq: "Cram Class"}}, sort: {fields: OrderNumber}) {
				edges {
					node {
            Id
            Name
            SlugURL
            ThumbnailURL
            Duration
            Description
					}
				}
			}
		}
	`);

	return (
		<div className="row">
			{data.allCourseDataset.edges.map(({ node }) => {
				return (
          <div className="col-md-6 col-lg-4 col-xl-4" style={{ marginBottom: '20px' }}>
            <Card className="cardShadow">
							<img alt="Crampete Cram Course" src={node.ThumbnailURL} />
							<div className="dvCourseHeader">
								<div className="dvCourseTag" style={{color:"white"}}>
									<FontIcon tag="faGraduationCap" />
								</div>
								<span>{node.Duration}</span>
							</div>
              <Card.Body>
								<span className="spnCramdegree">Cramdegree</span>
                <Card.Title style={{clear:"both"}}>{node.Name}</Card.Title>
                <Card.Text>
									<div
	                  dangerouslySetInnerHTML={{ __html: node.Description }}
	                />
                </Card.Text>
              </Card.Body>
              <ListGroup className="list-group-flush" style={{display:"none"}}>
                <ListGroupItem>Duration: {node.Duration}</ListGroupItem>
                <ListGroupItem>Batch Start Date : {node.BatchStartDate}</ListGroupItem>
              </ListGroup>
              <Card.Body>
                <Link className="btn btn-primary" to={"/courses/"+node.SlugURL}>View More</Link>
              </Card.Body>
            </Card>
        </div>
        )
			})}
		</div>
	);
};

export default Coursecard;
